const KTBlockUI = function(e, t) {
    let n = this;
    if (null != e) {
        let i = {
                zIndex: !1,
                overlayClass: '',
                overflow: 'hidden',
                message: '<span class="spinner-border text-primary"></span>',
            },
            r = function() {
                (n.options = KTUtil.deepExtend({}, i, t)), (n.element = e), (n.overlayElement = null), (n.blocked = !1), (n.positionChanged = !1), (n.overflowChanged = !1), KTUtil.data(n.element).set('blockui', n);
            };
        KTUtil.data(e).has('blockui') ? (n = KTUtil.data(e).get('blockui')) : r(),
            (n.block = function() {
                !(function() {
                    if (!1 !== KTEventHandler.trigger(n.element, 'kt.blockui.block', n)) {
                        let e = 'BODY' === n.element.tagName,
                            t = KTUtil.css(n.element, 'position'),
                            i = KTUtil.css(n.element, 'overflow'),
                            r = e ? 1e4 : 1;
                        n.options.zIndex > 0 ? (r = n.options.zIndex) : 'auto' != KTUtil.css(n.element, 'z-index') && (r = KTUtil.css(n.element, 'z-index')),
                            n.element.classList.add('blockui'),
                        ('absolute' !== t && 'relative' !== t && 'fixed' !== t) || (KTUtil.css(n.element, 'position', 'relative'), (n.positionChanged = !0)),
                        'hidden' === n.options.overflow && 'visible' === i && (KTUtil.css(n.element, 'overflow', 'hidden'), (n.overflowChanged = !0)),
                            (n.overlayElement = document.createElement('DIV')),
                            n.overlayElement.setAttribute('class', 'blockui-overlay ' + n.options.overlayClass),
                            (n.overlayElement.innerHTML = n.options.message),
                            KTUtil.css(n.overlayElement, 'z-index', r),
                            n.element.append(n.overlayElement),
                            (n.blocked = !0),
                            KTEventHandler.trigger(n.element, 'kt.blockui.after.blocked', n);
                    }
                })();
            }),
            (n.release = function() {
                !1 !== KTEventHandler.trigger(n.element, 'kt.blockui.release', n) &&
                (n.element.classList.add('blockui'),
                n.positionChanged && KTUtil.css(n.element, 'position', ''),
                n.overflowChanged && KTUtil.css(n.element, 'overflow', ''),
                n.overlayElement && KTUtil.remove(n.overlayElement),
                    (n.blocked = !1),
                    KTEventHandler.trigger(n.element, 'kt.blockui.released', n));
            }),
            (n.isBlocked = function() {
                return n.blocked;
            }),
            (n.destroy = function() {
                KTUtil.data(n.element).remove('blockui');
            }),
            (n.on = function(e, t) {
                return KTEventHandler.on(n.element, e, t);
            }),
            (n.one = function(e, t) {
                return KTEventHandler.one(n.element, e, t);
            }),
            (n.off = function(e) {
                return KTEventHandler.off(n.element, e);
            }),
            (n.trigger = function(e, t) {
                return KTEventHandler.trigger(n.element, e, t, n, t);
            });
    }
};
KTBlockUI.getInstance = function(e) {
    return null !== e && KTUtil.data(e).has('blockui') ? KTUtil.data(e).get('blockui') : null;
};

module.exports = KTBlockUI;
